/**
 * Returns the first matching value between the two given arrays
 *
 * @param {array} array strings array
 * @param {array} arrayCompare strings array
 * @returns { array | null } string array otherwise null
 */
export const arrayMatchValues = (array, arrayCompare) => {
    if (array?.length <= 0 || arrayCompare?.length <= 0) return null;

    const match = array?.find(Set.prototype.has, new Set(arrayCompare));

    return match || null;
};
