import { useFetch } from '@/hooks/useFetch';
import { servlets } from '@/utils/servlets';

/**
 * useGenericLists hook
 *
 * @param query required - listName
 * @param key optional - search key `&AMG`
 *
 * @returns { list, error, loading } object
 */
const useGenericLists = (query, key) => {
    const { data, loading, error } = useFetch(
        null,
        `${servlets.genericLists}${query ? `?listName=${query}` : ''}${
            key ? `&searchKey=${key}` : ''
        }`,
    );

    return { data, error, loading };
};

export default useGenericLists;
