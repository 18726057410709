import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { addDays, normalizeTimeSlots } from '@/utils/normalizeTimeSlots';

const usePhysicianBioAvailabilityDate = ({
    npi,
    newPatientAppointmentIds,
    departmentIds = null,
    range = 60,
}) => {
    const [availabilityDate, setAvailabilityDate] = useState(null);
    const [timeSlots, setTimeSlots] = useState(null);
    const [startDate, endDate, visitTypeIds] = useMemo(() => {
        const startDate = moment(new Date()).format('YYYY-MM-DD');
        const endDate = moment(new Date()).add(range, 'days').format('YYYY-MM-DD');
        const visitTypeIds = newPatientAppointmentIds?.filter((item) => item).join(',');
        return [startDate, endDate, visitTypeIds];
    }, [newPatientAppointmentIds, range]);

    const fetchAvailabilityData = useCallback(
        async (npi, visitTypeIds, departmentIds, startDate, endDate, signal = null) => {
            const path = '/bin/ahs/providerAvailability';
            const paramsObj = {
                npi,
                visitTypeIds,
                departmentIds,
                startDate,
                endDate,
            };
            const newSearchParams = new URLSearchParams();
            for (const param in paramsObj) {
                if (paramsObj[param]) {
                    newSearchParams.set(param, paramsObj[param]);
                }
            }
            const url = `${path}?${newSearchParams.toString()}`;
            const requestHeaders = new Headers();
            const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            requestHeaders.append('X-TIMEZONE', timeZone);
            const response = await fetch(url, { method: 'GET', headers: requestHeaders, signal });

            if (!response.ok) {
                throw new Error(`Error: Status ${response.status},${response.message}`);
            }
            const data = await response.json();

            return data.timeSlots;
        },
        [],
    );

    useEffect(() => {
        const controller = new AbortController();
        const getAvailabilityDate = async () => {
            if (!npi) {
                return;
            }
            try {
                const timeslots = await fetchAvailabilityData(
                    npi,
                    visitTypeIds,
                    departmentIds,
                    startDate,
                    endDate,
                    controller.signal,
                );
                if (typeof timeslots === 'object' && Object.keys(timeslots).length > 0) {
                    const firstDate = Object.keys(timeslots)[0];
                    setAvailabilityDate(firstDate);
                    setTimeSlots(normalizeTimeSlots(timeslots, firstDate, addDays(firstDate, 3)));
                }
            } catch (err) {
                if (err.name === 'AbortError') {
                    console.log('Fetch Aborted');
                    return;
                }
                setAvailabilityDate(null);
                setTimeSlots(null);
            }
        };

        getAvailabilityDate();

        return () => controller.abort();
    }, [npi, visitTypeIds, departmentIds, startDate, endDate, fetchAvailabilityData]);

    return { availabilityDate, timeSlots };
};

export default usePhysicianBioAvailabilityDate;
