import { useCallback, useEffect, useState } from 'react';

import window from '@/utils/window';

/**
 * useFetch hook
 *
 * @param origin optional
 * @param path required
 *
 * @returns { data, error, loading }
 */
const useFetch = (origin, path) => {
    const [data, setData] = useState(null);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const domainName = origin ? origin : window?.location?.origin;

    const fetchData = useCallback(async () => {
        setLoading(true);
        try {
            const response = await fetch(`${domainName}${path}`);
            const data = await response.json();

            setData(data);
            setLoading(false);
        } catch (error) {
            setError(true);
            setLoading(false);
        }
    }, [domainName, path]);

    useEffect(() => {
        if (!path) {
            return;
        }

        fetchData();

        return () => {
            setLoading(true);
        };
    }, [path, fetchData]);

    return { data, error, loading };
};

export default useFetch;
