import moment from 'moment';

import { isObject } from './defaultValues';

export const addDays = (startDate, numOfDays) => {
    if (startDate && !isNaN(numOfDays)) {
        return moment(startDate).add(numOfDays, 'days').format('YYYY-MM-DD');
    }
    return null;
};

export const convertTime = (timeString) => {
    let hours = parseInt(timeString.substring(0, 2));
    const minutes = timeString.substring(3, 5);
    const ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12;
    return `${hours}:${minutes}${ampm}`;
};

export const normalizeTimeSlots = (data, startDate, endDate) => {
    if (!startDate) return;
    let numberOfDays = 4;
    if (endDate) {
        const dateS = moment(startDate);
        const dateE = moment(endDate);
        numberOfDays = dateE.diff(dateS, 'days');
    }

    const allDates = [];
    for (let i = 0; i <= numberOfDays; i++) {
        allDates.push(addDays(startDate, i));
    }

    const result = allDates.map((date) => {
        let times = [];
        const d = moment(date).format('ddd,MMM DD').split(',');
        if (isObject(data) && data[date] && Array.isArray(data[date])) {
            times = data[date].map((item) => convertTime(item.time));
        }

        return {
            date: date,
            weekday: d[0],
            monthDay: d[1],
            times: times,
        };
    });

    return result;
};
